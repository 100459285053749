import { Button } from '@chakra-ui/react'
import { signOut } from 'next-auth/react'

interface ErrorDisplayProps {
  error: unknown
  onResetError: () => void
}

export function ErrorDisplay({ onResetError }: ErrorDisplayProps) {
  return (
    <div className="flex flex-col justify-center items-center h-[100vh]">
      <h2>Oops, something went wrong!</h2>
      <div className="flex flex-nowrap items-center space-x-4">
        <Button
          variant="outline"
          onClick={() => {
            void signOut({ callbackUrl: 'signOut' })
          }}
        >
          Sign out
        </Button>
        <p>or</p>
        <Button
          variant="outline"
          onClick={() => {
            onResetError()
          }}
        >
          Try again?
        </Button>
      </div>
    </div>
  )
}
