'use client'

import { useEffect } from 'react'
import { ErrorDisplay } from '@/components/error-display/error-display'
import { useRollbar } from '@wl/rollbar'

interface ErrorProps {
  error: Error
  reset: () => void
}

export default function Error({ error, reset }: ErrorProps) {
  const rollbar = useRollbar()

  useEffect(() => {
    // Log the error to an error reporting service
    rollbar?.error(error)
  }, [error, rollbar])

  return <ErrorDisplay error={error} onResetError={reset} />
}
